
import { Vue, Component } from 'vue-property-decorator'
import Echarts from 'echarts'
import { WorkOrderStatistics, CompleteTableData, AnalysisTableData } from '@/types/newOrder'
import { DicInfo } from '@/types/common'
import { dayFormat } from '@/utils/formatDate'
@Component
export default class Statistics extends Vue {
  private projectTypeList: Array<DicInfo> = []
  private projectList: Array<any> = []
  private date: [string, string] | [] = []
  private searchInfo = {
    projectType: '',
    projectIdList: []
  }

  private searchSubmitInfo: object | boolean = false
  private preChart: any = null
  private pieChartColor = ['#59CB74', '#FBD44C', '#FF587A', '#47A2FF', '#53C8D1']
  private barChart: any = null
  private orderTypeList = ['已完成', '待审核', '待反馈', '待返工', '已关闭']
  private completeSearch = 1 // 工单完成统计 1: 项目。 2：类型
  private completeChart: any = null
  private analysisSearch = 1 // 工单总数环比分析 1: 项目。 2：类型
  private analysisChart: any = null
  private legend: Array<string> = []
  private completeTableData: Array<CompleteTableData> = []
  private analysisTableData: Array<AnalysisTableData> = []
  private completeLoading = false
  private analysisLoading = false

  created (): void {
    this.initDate()
    this.getProjectType()
    this.getProjectList()
    this.searchData()
  }

  mounted () {
    window.addEventListener('resize', this.changeResize)
  }

  destroyed () {
    window.removeEventListener('resize', this.changeResize)
  }

  changeResize () {
    this.preChart && this.preChart.resize()
    this.barChart && this.barChart.resize()
    this.completeChart && this.completeChart.resize()
    this.analysisChart && this.analysisChart.resize()
  }

  initDate () {
    const start = new Date()
    const end = new Date()
    start.setDate(1)
    this.date = [
      dayFormat(start.getTime()),
      dayFormat(end.getTime())
    ]
  }

  getProjectType () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'projectType'
    }).then((res) => {
      this.projectTypeList = res.projectType || []
    })
  }

  // 获取项目
  getProjectList () {
    this.searchInfo.projectIdList = []
    this.$axios.get(this.$apis.project.selectProjectByList, {
      projectType: this.searchInfo.projectType
    }).then((res) => {
      this.projectList = res.list || []
    })
  }

  // 搜索
  searchData () {
    this.setSearchInfo()
    this.getData()
    this.getCompleteData()
    this.getAnalysisData()
  }

  setSearchInfo () {
    if (this.date && this.date.length) {
      this.searchSubmitInfo = {
        startDate: this.date[0],
        endDate: this.date[1]
      }
      if (this.searchInfo.projectIdList && this.searchInfo.projectIdList.length) {
        this.searchSubmitInfo = { ...this.searchSubmitInfo, projectIdList: this.searchInfo.projectIdList }
      }
      if (this.searchInfo.projectType) {
        this.searchSubmitInfo = { ...this.searchSubmitInfo, projectType: this.searchInfo.projectType }
      }
    } else {
      this.$message.warning('请选择统计日期区间')
      this.searchSubmitInfo = false
    }
  }

  // 获取工单状态统计数据和工单分类统计数据
  getData () {
    if (this.searchSubmitInfo) {
      this.$axios.post(this.$apis.newOrder.selectFlowStatistics, this.searchSubmitInfo).then((res) => {
        if (res) {
          this.drawPreChart(res.orderStatus)
          this.drawBarChart(res)
        }
      })
    }
  }

  // 获取工单完成统计数据
  getCompleteData () {
    this.completeLoading = true
    if (this.searchSubmitInfo) {
      const url = this.completeSearch === 1
        ? this.$apis.newOrder.selectOrderCompletionByProject
        : this.$apis.newOrder.selectOrderCompletionByType
      this.$axios.post(url, this.searchSubmitInfo).then((res) => {
        if (res) {
          this.drawCompleteChart(res)
          this.formatCompleteData(res)
        }
      }).finally(() => {
        this.completeLoading = false
      })
    }
  }

  // 获取工单总数环比统计数据
  getAnalysisData () {
    this.analysisLoading = true
    if (this.searchSubmitInfo) {
      const url = this.analysisSearch === 1
        ? this.$apis.newOrder.selectOrderAnalysisByProject
        : this.$apis.newOrder.selectOrderAnalysisByType
      this.$axios.post(url, this.searchSubmitInfo).then((res) => {
        if (res) {
          this.drawAnalysisChart(res)
          this.formatAnalysisData(res)
        }
      }).finally(() => {
        this.analysisLoading = false
      })
    }
  }

  // 工单完成统计项目和类型切换
  onChangeComplete (num: number) {
    if (this.completeSearch !== num) {
      this.setSearchInfo()
      if (this.searchSubmitInfo) {
        this.completeSearch = num
      }
      this.getCompleteData()
    }
  }

  // 工单总数环比统计项目和类型切换
  onChangeAnalysis (num: number) {
    if (this.analysisSearch !== num) {
      this.setSearchInfo()
      if (this.searchSubmitInfo) {
        this.analysisSearch = num
      }
      this.getAnalysisData()
    }
  }

  // 饼图
  drawPreChart (orderStatus: any) {
    this.$nextTick(() => {
      let preData: { name: string; count: number; value: number }[] = []
      const countArr: number[] = orderStatus ? [
        orderStatus.completed || 0,
        orderStatus.waitVerify || 0,
        orderStatus.waitFeedback || 0,
        orderStatus.reject || 0,
        orderStatus.orderClose || 0
      ] : [0, 0, 0, 0, 0]
      const total = countArr.reduce((total, val) => total + val)
      if (total !== 0) {
        const percentArr: Array<number> = []
        countArr.forEach((item) => {
          percentArr.push(Math.round((item * 100) / total))
        })
        preData = this.orderTypeList.map((item, index) => {
          return {
            name: item,
            count: countArr[index],
            value: percentArr[index]
          }
        })
        preData[percentArr.length - 1].value += 100 - percentArr.reduce((num, val) => num + val)
      } else {
        preData = this.orderTypeList.map((item, index) => {
          return {
            name: item,
            count: countArr[index],
            value: 0
          }
        })
      }
      this.preChart && this.preChart.clear()
      this.preChart = Echarts.init(this.$refs.preChart as any)
      const options = {
        tooltip: {
          show: true,
          formatter: '{a} <br/>{b}: {d}%'
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: '60%',
          icon: 'circle',
          itemWidth: 10,
          formatter: (name: any) => {
            const index = preData.findIndex((item) => item.name === name)
            const arr = '{a|' + name + '} | {b|' + preData[index].value + '%} {c|' + preData[index].count + '}'
            return arr
          },
          textStyle: {
            color: '#666666',
            rich: {
              a: {
                color: '#666666',
                fontSize: 14,
                align: 'center',
                padding: [15, 8, 15, 8]
              },
              b: {
                color: '#999999',
                fontSize: 14,
                align: 'center',
                padding: [15, 0, 15, 8]
              },
              c: {
                fontSize: 14,
                fontWeight: 'bold',
                align: 'center',
                padding: [15, 0, 15, 8]
              }
            }
          }
        },
        color: this.pieChartColor,
        series: [
          {
            name: '工单状态统计',
            // avoidLabelOverlap: false,
            hoverOffset: 0,
            type: 'pie',
            radius: '60%',
            center: ['30%', '50%'],
            label: {
              show: false,
              position: 'center'
            },
            zlevel: 4,
            itemStyle: {
              normal: {
                color: (value: any) => {
                  return this.pieChartColor[value.dataIndex]
                }
                // shadowColor: 'rgba(0,0,0,0.4)',
                // shadowBlur: 10
              }
            },
            data: preData,
            labelLine: {
              show: false
            }
          },
          {
            name: '工单状态统计',
            labelLine: {
              show: false
            },
            itemStyle: {
              opacity: 0.2,
              borderColor: '#FFFFFF',
              borderWidth: 0
            },
            type: 'pie',
            hoverAnimation: false,
            radius: ['60%', '70%'],
            center: ['30%', '50%'],
            label: {
              normal: {
                show: false
              }
            },
            data: preData,
            zlevel: -1
          }
        ]
      }
      this.preChart.setOption(options)
    })
  }

  // 柱状图
  drawBarChart (chartData: WorkOrderStatistics) {
    this.$nextTick(() => {
      this.barChart && this.barChart.clear()
      this.barChart = Echarts.init(this.$refs.barChart as any)
      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: this.orderTypeList,
          left: 0,
          top: 10,
          itemHeight: 1,
          itemWidth: 20,
          textStyle: {
            color: '#8C8C8C',
            fontSize: 12,
            padding: [0, 10, 0, 0]
          }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: chartData && chartData.orderTypeNameList ? chartData.orderTypeNameList : [],
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: (value: string) => {
                if (value.length > 8) {
                  return `${value.substring(0, 8)}...`
                } else {
                  return value
                }
              },
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              show: false
            },
            min: 0
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0,
            realtime: true,
            show: true,
            showDetail: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            zoomLock: true,
            width: '100%',
            height: 12,
            type: 'slider',
            orient: 'horizontal',
            fillerColor: '#ddd',
            showDataShadow: false,
            dataBackground: {
              lineStyle: {
                width: 0,
                opacity: 1
              }
            },
            handleStyle: {
              // borderWidth: 0,
              // color: '#ddd',
              // height: 12,
              opacity: 0
            },
            startValue: 0,
            endValue: 4,
            left: 'center'
          }
        ],
        series: [
          {
            name: '已完成',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            color: '#59CB74',
            data: chartData && chartData.completedList ? chartData.completedList : [],
            barWidth: 20
          },
          {
            name: '待审核',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            color: '#FFD300',
            data: chartData && chartData.waitVerifyList ? chartData.waitVerifyList : [],
            barWidth: 20
          },
          {
            name: '待反馈',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            color: '#FF587A',
            data: chartData && chartData.waitFeedbackList ? chartData.waitFeedbackList : [],
            barWidth: 20
          },
          {
            name: '待返工',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            color: '#00A4FF',
            data: chartData && chartData.rejectList ? chartData.rejectList : [],
            barWidth: 20
          },
          {
            name: '已关闭',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            color: '#53C8D1',
            data: chartData && chartData.orderCloseList ? chartData.orderCloseList : [],
            barWidth: 20
          }
        ]
      }
      this.barChart.setOption(options)
    })
  }

  // 工单完成统计图标
  drawCompleteChart (data: { notFinished: Array<any>; complete: Array<any>; completionRate: Array<any>; projectName?: Array<any>; orderTypeName?: Array<any> }) {
    const notFinished = data && data.notFinished ? data.notFinished : []
    const complete = data && data.complete ? data.complete : []
    const completionRate = data && data.completionRate ? data.completionRate : []
    const arrAll = data.notFinished.map((item, index) => {
      return item + (complete[index] || 0)
    })
    const splitNumber = 5
    const y1Max = Math.max(...arrAll) + 10
    const y1Min = 0
    const y2Max = Math.max(...completionRate) >= 100 ? Math.max(...completionRate) : 100
    const y2Min = 0
    const legend = ['已完成', '未完成']
    if (y2Max !== y2Min) {
      legend.push('完成率')
    }
    this.$nextTick(() => {
      this.completeChart && this.completeChart.clear()
      this.completeChart = Echarts.init(this.$refs.completeChart as any)
      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (res: Array<any>) => {
            let tip = `${res[0].name}`
            res.forEach((item, index) => {
              tip += `<br>${item.seriesName}：${item.value || item.value === 0 ? item.value : '-'}${index === 2 ? '%' : ''}`
            })
            return tip
          }
        },
        legend: {
          data: legend,
          left: 0,
          top: 10,
          itemHeight: 1,
          itemWidth: 20,
          textStyle: {
            color: '#8C8C8C',
            fontSize: 12,
            padding: [0, 10, 0, 0]
          }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data && (data.projectName || data.orderTypeName) ? (data.projectName || data.orderTypeName) : [],
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: (value: string) => {
                if (value.length > 8) {
                  return `${value.substring(0, 8)}...`
                } else {
                  return value
                }
              },
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              show: false
            },
            min: 0
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: splitNumber,
            min: y1Min,
            max: y1Max,
            interval: Number(((y1Max - y1Min) / splitNumber).toFixed(2)),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          },
          {
            type: 'value',
            splitNumber: splitNumber,
            min: y2Min,
            max: y2Max,
            interval: Number(((y2Max - y2Min) / splitNumber).toFixed(2)),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)',
              formatter: '{value} %'
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0,
            realtime: true,
            show: true,
            showDetail: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            zoomLock: true,
            width: '100%',
            height: 12,
            type: 'slider',
            orient: 'horizontal',
            fillerColor: '#ddd',
            showDataShadow: false,
            dataBackground: {
              lineStyle: {
                width: 0,
                opacity: 1
              }
            },
            handleStyle: {
              // borderWidth: 0,
              // color: '#ddd',
              // height: 12,
              opacity: 0
            },
            startValue: 0,
            endValue: 4,
            left: 'center'
          }
        ],
        series: [
          {
            name: legend[0],
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: complete,
            color: '#52C41A',
            barWidth: 20
          },
          {
            name: legend[1],
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: notFinished,
            color: '#FD9401',
            barWidth: 20
          },
          {
            name: legend[2] || '',
            type: 'line',
            symbolSize: 2,
            yAxisIndex: 1,
            smooth: true,
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: 'rgba(18, 194, 194, 1)'
              }
            },
            lineStyle: {
              width: 4,
              shadowBlur: 0,
              shadowColor: 'rgba(54, 203, 203, 0.2)',
              shadowOffsetY: 20
            },
            data: completionRate
          }
        ]
      }
      this.completeChart.setOption(options)
    })
  }

  // 工单完成统计表格
  formatCompleteData (data: { notFinished: Array<any>; complete: Array<any>; completionRate: Array<any>; projectName?: Array<any>; orderTypeName?: Array<any>; orderTotal: Array<any> }) {
    const notFinished = data && data.notFinished ? data.notFinished : []
    const complete = data && data.complete ? data.complete : []
    const completionRate = data && data.completionRate ? data.completionRate : []
    const orderTotal = data && data.orderTotal ? data.orderTotal : []
    const name = data && (data.projectName || data.orderTypeName) ? (data.projectName || data.orderTypeName) : []
    if (name) {
      this.completeTableData = name.map((name, i) => ({
        name,
        orderTotal: orderTotal[i],
        complete: complete[i],
        notFinished: notFinished[i],
        completionRate: completionRate[i]
      }))
    }
  }

  // 工单总数环比图标
  drawAnalysisChart (data: { currentPeriod: Array<any>; lastPeriod: Array<any>; proportion: Array<any>; projectName?: Array<any>; orderTypeName?: Array<any>; dq: string; sq: string }) {
    const currentPeriod = data && data.currentPeriod ? data.currentPeriod : []
    const lastPeriod = data && data.lastPeriod ? data.lastPeriod : []
    const proportion = data && data.proportion ? data.proportion : []
    const arrAll = [...currentPeriod, ...lastPeriod]
    const splitNumber = 5
    const y1Max = Math.max(...arrAll) + 10
    const y1Min = 0
    const y2Max = Math.max(...proportion) >= 100 ? Math.max(...proportion) : 100
    const y2Min = Math.min(...proportion)
    this.legend = [`当期${data.dq || ''}`, `上期${data.sq || ''}`]
    if (y2Max !== y2Min) {
      this.legend.push('环比')
    }
    this.$nextTick(() => {
      this.analysisChart && this.analysisChart.clear()
      this.analysisChart = Echarts.init(this.$refs.analysisChart as any)
      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (res: Array<any>) => {
            let tip = `${res[0].name}`
            res.forEach((item, index) => {
              tip += `<br>${item.seriesName}：${item.value || item.value === 0 ? item.value : '-'}${index === 2 ? '%' : ''}`
            })
            return tip
          }
        },
        grid: {
          left: '0',
          right: '0',
          top: 60,
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data && (data.projectName || data.orderTypeName) ? (data.projectName || data.orderTypeName) : [],
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: (value: string) => {
                if (value.length > 8) {
                  return `${value.substring(0, 8)}...`
                } else {
                  return value
                }
              },
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              show: false
            },
            min: 0
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: splitNumber,
            min: y1Min,
            max: y1Max,
            interval: Number(((y1Max - y1Min) / splitNumber).toFixed(2)),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          },
          {
            type: 'value',
            splitNumber: splitNumber,
            min: y2Min,
            max: y2Max,
            interval: Number(((y2Max - y2Min) / splitNumber).toFixed(2)),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.45)',
              formatter: '{value} %'
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0,
            realtime: true,
            show: true,
            showDetail: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            zoomLock: true,
            width: '100%',
            height: 12,
            type: 'slider',
            orient: 'horizontal',
            fillerColor: '#ddd',
            showDataShadow: false,
            dataBackground: {
              lineStyle: {
                width: 0,
                opacity: 1
              }
            },
            handleStyle: {
              // borderWidth: 0,
              // color: '#ddd',
              // height: 12,
              opacity: 0
            },
            startValue: 0,
            endValue: 4,
            left: 'center'
          }
        ],
        series: [
          {
            name: '当期',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: currentPeriod,
            color: '#1AD1B0',
            barWidth: 20
          },
          {
            name: '上期',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: lastPeriod,
            color: '#1EA2FF',
            barWidth: 20
          },
          {
            name: '环比',
            type: 'line',
            symbolSize: 0,
            yAxisIndex: 1,
            smooth: true,
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: 'rgba(47, 194, 90, 1)'
              }
            },
            lineStyle: {
              width: 4,
              shadowBlur: 0,
              shadowColor: 'rgba(54, 203, 203, 0.2)',
              shadowOffsetY: 20
            },
            data: proportion
          }
        ]
      }
      this.analysisChart.setOption(options)
    })
  }

  // 工单总数环比表格
  formatAnalysisData (data: { proportion: Array<any>; lastPeriod: Array<any>; currentPeriod: Array<any>; projectName?: Array<any>; orderTypeName?: Array<any> }) {
    const proportion = data && data.proportion ? data.proportion : []
    const lastPeriod = data && data.lastPeriod ? data.lastPeriod : []
    const currentPeriod = data && data.currentPeriod ? data.currentPeriod : []
    const name = data && (data.projectName || data.orderTypeName) ? (data.projectName || data.orderTypeName) : []
    if (name) {
      this.analysisTableData = name.map((name, i) => ({
        name,
        proportion: proportion[i],
        lastPeriod: lastPeriod[i],
        currentPeriod: currentPeriod[i]
      }))
    }
  }
}
